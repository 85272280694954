<template>
	<div>
		<div class="d-flex align-items-center">
			<div class="flex-grow-1">
				<b-form-input @input="handleInput" type="number" v-model="from"></b-form-input>
			</div>
			<div class="px-3">-</div>
			<div class="flex-grow-1">
				<b-form-input @input="handleInput" type="number" v-model="to"></b-form-input>
			</div>
		</div>
		<span class="invalid-feedback-custom"
		      v-show="validateError"
		      v-html="validateError">
        </span>
	</div>
</template>

<script>
	export default {
		props: {
			value: {
				default: null
			},
			validateError: {
				type: String,
				default: ''
			},
		},
		watch: {
			value: function (newValue) {
				this.setValue(newValue);
			}
		},
		created() {
			this.setValue(this.value)
		},
		data() {
			return {
				data: null,
				from: null,
				to: null
			}
		},
		methods: {
			setValue(newValue) {
				if(newValue){
					const [from, to] = newValue.split(',');
					if (from) {
						this.from = from
					}
					if (to) {
						this.to = to
					}
				}
			},
			handleInput() {
				let input = [];
				input.push(this.from ? this.from : 0);
				input.push(this.to ? this.to : 0);
				
				this.$emit('input', input.join(','))
			}
		}
	}
</script>
